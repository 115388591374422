import * as PropTypes from "prop-types"
import HomeScene from "@/scenes/HomeScene"

import { createClient } from "contentful"

const client = createClient({
  space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN
})

function Home({ posts = [] }) {
  return <HomeScene posts={posts} />
}

Home.propTypes = {
  posts: PropTypes.array
}

export async function getStaticProps() {
  const entries = await client.getEntries({
    content_type: "blogPost",
    limit: 4,
    order: "-fields.publishDate"
  })

  return { props: { posts: entries.items }, revalidate: 60 }
}

export default Home
