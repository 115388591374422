import Typography from "@/styled/Typography"
import { Container } from "@/styled/Grid"
import styled from "styled-components"
import { WhatsappCta } from "@/styled/Buttons"
import { sendEvent } from "@/scripts/GoogleAnalytics"

const Background = styled.div``

const Wrapper = styled.div`
  width: 100%;
  padding-top: 130px;
  padding-bottom: 50px;
  background: linear-gradient(rgba(2, 24, 89, 0.8), rgba(2, 24, 89, 0.5));
`

export default function Header(props) {
  const { page = "", title = "Physio WOW", subtitle = "Tu centro de fisioterapia", big = false, shareText, phoneNumber } = props
  const onClickCta = () => {
    sendEvent("click CTA", { event_category: page, event_label: "whatsapp header" })
  }

  return (
    <Background>
      <Wrapper>
        <Container>
          <Typography variant="title1" $txtColor="white" $weight="bold">
            {title}
          </Typography>
          <Typography variant="title2" className="mt-3 mb-4" $txtColor="white" $weight="medium">
            {subtitle}
          </Typography>
          <WhatsappCta $white shareText={shareText} onClick={onClickCta} phoneNumber={phoneNumber} />
        </Container>
      </Wrapper>
    </Background>
  )
}
